import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUsers,
  faUserCog,
  faHome,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/logo.png';
import {useHistory} from 'react-router-dom';
import {deleteCookies} from '../utils/functions';
import cookie from '../services/cookie/index';

export default function Main(props) {
  const {Page} = props;

  let history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  let role = cookie().get("role")

  return (
    <div>
      <Navbar color="faded" expand="md" light style={{borderBottom:'1px solid #ddd', background:'#FFF'}}>
        <NavbarBrand onClick={() => history.push({ pathname:"/"})} className="mr-auto">
          <img src={logo} alt="Logo CEDAP" style={{height:'32px'}}/>
        </NavbarBrand>

        <NavbarToggler onClick={toggleNavbar} className="mr-2" />

        <Collapse isOpen={!collapsed} navbar>
          <Nav className="mr-auto" navbar>

            <NavItem>
              <NavLink style={{cursor:'pointer'}} onClick={() => {setCollapsed(true); history.push({ pathname:"/"})}} ><FontAwesomeIcon icon={faHome} /> Home</NavLink>
            </NavItem>
            
            {role === "admin"&&
              <NavItem>
                <NavLink style={{cursor:'pointer'}} onClick={() => {setCollapsed(true); history.push({ pathname:"/usuarios"})}} ><FontAwesomeIcon icon={faUserCog} /> Usuarios</NavLink>
              </NavItem>
            }

            {role === "super_medico"&&
              <NavItem>
                <NavLink style={{cursor:'pointer'}} onClick={() => {setCollapsed(true); history.push({ pathname:"/grupos"})}} ><FontAwesomeIcon icon={faUsers} /> Grupos</NavLink>
              </NavItem>
            }
            
            <NavItem>
              <NavLink style={{cursor:'pointer'}} onClick={() => {setCollapsed(true); deleteCookies(); history.push({ pathname:"/login"})}} ><FontAwesomeIcon icon={faSignOutAlt} /> Sair</NavLink>
            </NavItem>

          </Nav>
        </Collapse>

      </Navbar>

      <Page/>
    </div>
  );

}
