import React from 'react';
import { Table, Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function Team() {
  const users = [
    {
      id: 1,
      firstName: "Mark",
      lastName: "Otto",
      user: "@mdo",
    },
    {
      id: 2,
      firstName: "Mark",
      lastName: "Otto",
      user: "@mdo",
    },
    {
      id: 3,
      firstName: "Mark",
      lastName: "Otto",
      user: "@mdo",
    },
    {
      id: 4,
      firstName: "Mark",
      lastName: "Otto",
      user: "@mdo",
    },
    {
      id: 5,
      firstName: "Mark",
      lastName: "Otto",
      user: "@mdo",
    },
    {
      id: 6,
      firstName: "Mark",
      lastName: "Otto",
      user: "@mdo",
    },
    {
      id: 7,
      firstName: "Mark",
      lastName: "Otto",
      user: "@mdo",
    },
  ];

  return (
    <Table striped>
      <thead>
        <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Username</th>
          <th>Funções</th>
        </tr>
      </thead>
      <tbody>

        {users.map((user) =>(
          <tr>
            <th scope="row">{user.id}</th>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.user}</td>
            <td>
              <ButtonGroup>
                <Button color="primary"><FontAwesomeIcon icon={faWrench} /></Button>
                <Button color="danger"><FontAwesomeIcon icon={faTrashAlt} /></Button>
              </ButtonGroup>
            </td>
          </tr>
        ))}
        
      </tbody>
    </Table>
  );
}

export default Team;
