import React, {useState} from 'react';
import { 
  Alert,
  Spinner,
  FormGroup,
  Form,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Row,
  Col,
} from 'reactstrap';
import { FormWrapperForm, InputView, WrapperEmailList } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function FormReportsGroup({groupsThatIBelong, fields, setFields, cleanSearch, inProgress, getReportsGroup}) {

  return (
    <FormWrapperForm>
      
      <Form onSubmit={getReportsGroup}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    Grupo
                  </InputGroupText>
                </InputGroupAddon>

                <InputView type="select" onChange={(e) => setFields({...fields, groupID:e.target.value})}>
                  {groupsThatIBelong.map((group) => (
                    <option value={group.id}>{group.name}</option>
                  ))}
                </InputView>

              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    Data Início
                  </InputGroupText>
                </InputGroupAddon>

                <InputView type="date" defaultValue={fields.dateStart} required onChange={(e) => setFields({...fields, dateStart:e.target.value})} />

              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    Data Fim
                  </InputGroupText>
                </InputGroupAddon>

                <InputView type="date" defaultValue={fields.dateEnd} required onChange={(e) => setFields({...fields, dateEnd:e.target.value})}/>

              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                Nome Paciente
              </InputGroupText>
            </InputGroupAddon>

            <InputView type="text" defaultValue={fields.patient} onChange={(e) => setFields({...fields, patient:e.target.value})} />

          </InputGroup>
        </FormGroup>
        


        { (inProgress) ?
            <Spinner color="primary" />
          :
          <>
            <Button color="primary" type="submit">
              <FontAwesomeIcon icon={faSearch} /> Buscar
            </Button>
            <Button color="primary" onClick={() => cleanSearch("")}>
              Limpar busca
            </Button>
          </>
        }
      </Form>

    </FormWrapperForm>
  );
}