import React from 'react';
import { Container } from './styles';
import { Spinner } from 'reactstrap';

export default function Loader() {
  return (
      <Container>
        <span><Spinner color="primary" /> Loading</span>
      </Container>
  );
}
