import React from 'react';
import slideImg from '../../assets/slide.png';
import { SlideView } from './styles';

export default function Slide({title}) {

  return (
    <SlideView src={slideImg}>
      <h1>{title}</h1>
    </SlideView>
  );
}