import React, {useCallback, useMemo, useState} from 'react';
import { Table, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { formatDate } from '../../utils/functions';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import {SessionManager} from '../../utils/functions';
import { Button } from './styles';

export default function ListReportsGroup({reports, message, getOtherPage, listPerPage, pageSelected, setPageSelected, qtdReports}) {
  
  const [inProgress, setInProgress] = useState({numberReport:null});
  const session = SessionManager();
  const token = cookie().get("token");
  const qtdPages = useMemo(() => Math.ceil(qtdReports/listPerPage), [qtdReports, listPerPage])

  const download = useCallback(async (numberReport, typeReport) => {
    setInProgress({numberReport:numberReport})

    const response = await api().report().download(token, numberReport, typeReport);
    
    if (response.status === 401) 
    {
      return session.logoff()
    } 
    
    if (response.status === 200) 
    {
      window.open(response.output)
      setInProgress({numberReport:null})
      return
    } 
    setInProgress({numberReport:null})
  }, [setInProgress, session, token]);

  const handleSelectPage = useCallback((page) => {
    getOtherPage(page)
    setPageSelected(page)
  }, [setPageSelected, getOtherPage])

  const Pagination = useMemo(() => {
    if (isNaN(qtdPages) || qtdPages === 1) {
      return <></>
    }

    const mapArray = [...Array(qtdPages)]

    return mapArray.map((_, key) => {
      const page = key + 1
      return(
        <Button activated={page===pageSelected} type="button" key={`pagination-${page}`} onClick={() => handleSelectPage(page)} >
          {page}
        </Button>
      )
    });

  }, [qtdPages, handleSelectPage, pageSelected])

  if (message === "") {
    return (<div></div>)
  }

  return (
    <div style={{marginTop:"50px"}}>
      <h5>
        <FontAwesomeIcon icon={faSearch} /> {message}
      </h5>

      {reports.length>0&&
        <Table striped bordered responsive style={{marginTop:"50px"}}>
          <thead>
            <tr>
              <th>#Exame</th>
              <th>Funções</th>
              <th>Paciente</th>
              <th>Data de Nascimento</th>
              <th>Instituição de origem</th>
              <th>Data da solicitação</th>

            </tr>
          </thead>
          <tbody>

            {reports.map((report) => (
              <tr>
                <th scope="row">{report.id_exam}</th>
                <td>
                  {report.can_download?
                    <Button disabled={inProgress.numberReport === report.id_exam} activated onClick={() => download(report.id_exam, report.type)}>
                    { (inProgress.numberReport === report.id_exam) ?
                      <><Spinner style={{ width: '1em', height: '1em' }} /> Downloading</>
                    :
                      <><FontAwesomeIcon icon={faDownload} /> Download</>
                    }
                    </Button>
                  :
                    "Documento não quitado."
                  }
                </td>
                <td>{report.patient}</td>
                <td>{formatDate(report.birthdate)}</td>
                <td>{report.institution}</td>
                <td>{formatDate(report.request_date)}</td>

              </tr>
            ))}
            
          </tbody>
        </Table>
      }
      {Pagination}
    </div>

  );
}