import React, {useState, useCallback} from 'react';
import ListReportsGroup from './list.jsx'
import FormReportsGroup from './form.jsx'
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import {SessionManager} from '../../utils/functions';

export default function ReportsGroup({groupsThatIBelong}) {

  const [fields, setFields] = useState({groupID: groupsThatIBelong[0].id, dateStart:'', dateEnd: '', patient: ''});
  const [reports, setReports] = useState([]);
  const [message, setMessage] = useState(""); 
  const [inProgress, setInProgress] = useState(false);
  const token = cookie().get("token");
  const listPerPage = 100
  const [qtdReports, setQtdReports] = useState(0);
  const session = SessionManager()
  const [pageSelected, setPageSelected] = useState(1);


  const cleanSearch = useCallback((message = "") => {
    setFields({dateStart:'', dateEnd: '', patient: ''})
    setMessage(message)
    setReports([])
  },[setFields, setMessage, setReports])

  const getSizeReportsGroup = useCallback(async () => {
    const response = await api().report().getSizeListByGroup(token, fields.dateStart, fields.dateEnd, fields.patient, fields.groupID);
    
    if (response.status === 401) 
    {
      await session.logoff();
      return 0
    } 
    
    if (response.status === 200) 
    {      
      return response.output
    } 
    return 0
  }, [fields, token, session])

  const getReportsGroup = useCallback(async (e) => {
    e.preventDefault();

    setInProgress(true)
    const size = await getSizeReportsGroup();

    if (size === 0) {
      cleanSearch("Nenhum laudo encontrado")
      setInProgress(false)
      setQtdReports(0)
      return
    }

    const response = await api().report().getByGroup(token, listPerPage, 1, fields.dateStart, fields.dateEnd, fields.patient, fields.groupID);

    if (response.status === 200) 
    {

      setQtdReports(size)
      setMessage(size +" resultados encontrados")
      setReports(response.output)
      setInProgress(false)
      setPageSelected(1)
      
      return
    } 

    cleanSearch("Nenhum laudo encontrado")
    setInProgress(false)
    
  },[setInProgress, fields, token, getSizeReportsGroup, setMessage, setReports, cleanSearch, setQtdReports])

  const getOtherPage = useCallback(async (page) => {
    const response = await api().report().getByGroup(token, listPerPage, page, fields.dateStart, fields.dateEnd, fields.patient, fields.groupID);

    if (response.status === 200) 
    {

      setReports(response.output)
      setInProgress(false)
      
      return
    } 

    setReports([])
  }, [setInProgress, setReports, token, fields])


  return (
    <div>
      <FormReportsGroup
        inProgress={inProgress}
        groupsThatIBelong={groupsThatIBelong}
        cleanSearch={cleanSearch}
        setFields={setFields}
        fields={fields}
        getReportsGroup={getReportsGroup}
      />
      <ListReportsGroup
        reports={reports}
        message={message}
        getOtherPage={getOtherPage}
        listPerPage={listPerPage}
        pageSelected={pageSelected}
        setPageSelected={setPageSelected}
        qtdReports={qtdReports}
      />
    </div>
  );
}