import React, {useState, useEffect} from 'react';
import { Table, Button, ButtonGroup, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../components/Loader';
import confirm from "reactstrap-confirm";
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import { useHistory } from 'react-router-dom';
import {formatDate, deleteCookies} from '../../utils/functions';

function MyGroups() {

  let [page, setPage] = useState(1)
  let [qtdPages, setQtdPages] = useState(0)
  let [inProgress, setInProgress] = useState(true);
  let [groups, setGroups] = useState([])
  let history = useHistory();

  useEffect(() => {
    getMyGroups()
  }, [])

  const getMyGroups = async () => {
    await setInProgress(true)

    const token = await cookie().get("token")
    const response = await api().group().getMyOwn(token);
    
    if (response.status === 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    if (response.status === 200) 
    {
      setGroups(response.output)
      setQtdPages(Math.round(response.output.length/15))
      setInProgress(false)
      
      return
    } 
    setInProgress(false)
    setGroups([])

  }

  const deleteGroup = async (id, name) => {
    let result = await confirm({
        title: "Excluir Grupo",
        message: (<>Você tem certeza que deseja excluir <b>{name}</b> ?</>),
        cancelText: "Cancelar",
        cancelColor: "secondary",
        confirmText: 'Excluir',
        confirmColor: 'danger',
    });

    if (result) {
      await setInProgress(true)

      const token = await cookie().get("token")

      const response = await api().group().deleteByID(token, id);
      
      if (response.status === 200) 
      {
        return history.go(0)
      } 
      setInProgress(false)
    }
  }

  const PaginationComponent = () => {
    let element = [];

    for (let index = page-3; index < page; index++) {
      let thisPage = index
      if (thisPage > 0) {
        element.push(
          <PaginationItem active={thisPage===page} >
            <PaginationLink onClick={() => setPage(thisPage)}>
            {thisPage}
            </PaginationLink>
          </PaginationItem>
        )

      }
    }

    for (let index = page-1; index < qtdPages && index < page+3; index++) {
      let thisPage = index+1
      element.push(
        <PaginationItem active={thisPage===page} >
          <PaginationLink onClick={() => setPage(thisPage)}>
          {thisPage}
          </PaginationLink>
        </PaginationItem>
      )
    }

    return (
      <Pagination aria-label="">

        <PaginationItem>
          <PaginationLink first onClick={() => setPage(1)} disabled={page===1} />
        </PaginationItem>

        <PaginationItem disabled={page===1}>
          <PaginationLink previous onClick={() => setPage(page-1)} />
        </PaginationItem>

        {element.map((e)=>e)}

        <PaginationItem disabled={page===qtdPages}>
          <PaginationLink next onClick={() => setPage(page+1)}/>
        </PaginationItem>
        
        <PaginationItem disabled={page===qtdPages}>
          <PaginationLink last onClick={() => setPage(qtdPages)} />
        </PaginationItem>
      </Pagination>
    )
  }
  
  return (
    
    <div>
      {(inProgress) && (<Loader/>)}
      
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Data Criação</th>
            <th>Funções</th>
          </tr>
        </thead>
        <tbody>

          {groups.slice(15*(page-1), 15*page).map((group) => (
            <tr>
              <th scope="row">{group.id}</th>
              <td>{group.name}</td>
              <td>{group.description}</td>
              <td>{formatDate(group.created_at)}</td>
              <td>
                <ButtonGroup>
                  <Button color="primary">
                    <FontAwesomeIcon icon={faWrench} />
                  </Button>
                  <Button 
                    color={'danger'}
                    title={'Excluir Grupo'}
                    onClick={() => deleteGroup(group.id, group.name)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
          
        </tbody>
      </Table>

      <PaginationComponent />
    </div>
  );
}

export default MyGroups;
