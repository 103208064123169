import ApiConnection from './connection';

// const download = async (token, numberReport, typeReport) => ApiConnection.get(`reports/download/${numberReport}/${typeReport}`, { headers: { Authorization: `Bearer ${token}` }})
// .then(response => {
//   return {output:response.data, status: response.status};
// }).catch((e) => {
//   return {status: e.response.status}
// });

const getSizeList = async (token, reportData) =>
	ApiConnection.post(`reports/size`, reportData, {
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return { output: response.data, status: response.status };
		})
		.catch((e) => {
			return { status: e.response.status };
		});

// const get = async (token, reportData) =>
// 	ApiConnection.post(`reports`, reportData, {
// 		headers: { Authorization: `Bearer ${token}` },
// 	})
// 		.then((response) => {
// 			return { output: response.data, status: response.status };
// 		})
// 		.catch((e) => {
// 			return { status: e.response.status };
// 		});

const getAll = async (token, reportData) =>
	ApiConnection.post(`reports`, reportData, {
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return { output: response.data, status: response.status };
		})
		.catch((e) => {
			return { status: e.response.status };
		});

const downloadHistology = async (token, numberReport) =>
	ApiConnection.get(`reports/histologia/${numberReport}`, {
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return { output: response.data, status: response.status };
		})
		.catch((e) => {
			return { status: e.response.status };
		});

const downloadColpocitology = async (token, numberReport) =>
	ApiConnection.get(`reports/colpocitologia/${numberReport}`, {
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return { output: response.data, status: response.status };
		})
		.catch((e) => {
			return { status: e.response.status };
		});

const getSizeListByGroup = async (token, dateStart, dateEnd, patient, groupID) => ApiConnection.get(`reports/size/${dateStart}/${dateEnd}/${groupID}/${patient}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const getByGroup = async (token, sizeList, page, dateStart, dateEnd, patient, groupID) => ApiConnection.get(`reports/${sizeList}/${page}/${dateStart}/${dateEnd}/${groupID}/${patient}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

export default function report() {
  return {
    // get,
	getAll,
	downloadColpocitology,
	downloadHistology,
    getByGroup,
    getSizeList,
    getSizeListByGroup,
    // download
  }
}