import React, { useState } from 'react';
import { Card, ButtonInsert } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faPlus } from '@fortawesome/free-solid-svg-icons'
import GroupsView from '../../views/MyGroups/index.jsx';
import Modal from '../../components/Modal/index.jsx';
import CreateGroup from '../../views/CreateGroup/index.jsx';
import Slide from '../../components/Slide';

export default function Groups() {
  const [modal, setModal] = useState(false);

  return (
    <div>
      
      <Slide title={<><FontAwesomeIcon icon={faUsers} /> Grupos</>}/>

      <Card>
        
        <GroupsView/>
        
        <Modal title="Novo Grupo" size="lg" modal={modal} setModal={setModal} Content={CreateGroup}/>

        <ButtonInsert title="Cadastrar novo" onClick={() => setModal(!modal)}>
          <FontAwesomeIcon icon={faPlus} />
        </ButtonInsert>
      </Card>
    </div>
  );
}
