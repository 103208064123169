import React, {useState} from 'react';
import { 
  Alert,
  Spinner,
  FormGroup,
  Form,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Label,
  Badge,
} from 'reactstrap';
import { FormWrapperForm, InputView, WrapperEmailList } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faUsers,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import api from '../../services/client/api';
import { useHistory } from 'react-router-dom';
import cookie from '../../services/cookie/index';
import {deleteCookies} from '../../utils/functions';

export default function CreateGroup() {

  let [name, setName] = useState("");
  let [description, setDescription] = useState("");
  let [inProgress, setInProgress] = useState(false);
  let [emailList, setEmailList] = useState([]);
  let [emailInput, setEmailInput] = useState('');
  let [error, setError] = useState(false);
  let [msgError, setMsgError] = useState('erro');
  let history = useHistory();


  const removeEmailList = (emailToRemove) => {
    emailList = emailList.filter(email => email !== emailToRemove);
    setEmailList([ ...emailList])
  }

  const addEmailList = () => {
    setEmailList([ ...emailList, emailInput])
    setEmailInput('')
  }

  const create = async (e) => {
    e.preventDefault();
    setInProgress(true);
    
    const token = await cookie().get("token")
    const response = await api().group().create(token, { name, description, emails:emailList })
    
    
    if (response.status === 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    if (response.status === 201) 
    {
      return history.go(0);
    } 

    setMsgError(response.message)
    setError(true)
    setInProgress(false);

  }

  return (
    <FormWrapperForm>
      
      {(error)&& <Alert color="danger">{msgError}</Alert>}

      <Form onSubmit={create}>
        
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon={faUsers} />
              </InputGroupText>
            </InputGroupAddon>

            <InputView placeholder="Nome" onChange={(e) => setName(e.target.value)} />

          </InputGroup>
        </FormGroup>
      
        <FormGroup>
          <InputView type="textarea" placeholder="Descrição" onChange={(e) => setDescription(e.target.value)} />
        </FormGroup>

        <FormGroup>
          <Label>Adicionar E-mails</Label>
          <InputGroup>
            <InputView placeholder="E-mail" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
            <InputGroupAddon addonType="append">
              <Button color="primary" onClick={addEmailList}>Adicionar</Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        
        <WrapperEmailList>
          {emailList.map((email) => (
            <Badge color="secondary" pill>
              {email} <FontAwesomeIcon onClick={() => removeEmailList(email)} icon={faTimes} />
            </Badge>
          ))}
          {emailList.length <= 0 && 'Nenhum e-mail adicionado'}
        </WrapperEmailList>


          { (inProgress) ?
              <Spinner color="primary" />
            :
            <Button color="primary" type="submit">
              <FontAwesomeIcon icon={faPlus} /> Cadastrar
            </Button>
          }
      </Form>

    </FormWrapperForm>
  );
}