import styled from 'styled-components';

export const SlideView = styled.div`
  position: relative;
  background: url(${(props) => (props.src)});
  background-size: 100% auto;
  height: 150px;
  font-weight: 900;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);

  h1 {
    color: #fff;
    position: absolute;
    text-align: left;
    width: 80%;
    left: 10%;
    top: 40%;
  }
`;
