const cookie = () => 
{
  
  const set = async (name, data, expday) => 
  {
              
    let date = new Date();
    date.setTime( date.getTime() + (expday * 24 * 60 * 60 * 1000) );
    const expires = "expires="+ date.toUTCString();
    
    document.cookie = name + "=" + data + ";" + expires + ";path=/";
  }

  const del = async (name) =>
  {

    let date = new Date();
		date.setTime(date.getTime());
    const expires = "expires="+ date.toUTCString();
    
    document.cookie = name + "=;" + expires + ";path=/"; 
    
  }

  const get = (name) =>
  {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) 
    {
      return match[2];
    } else {
      return null
    }
  }

  return {set, del, get}

}

export default cookie;