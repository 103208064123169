import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import cookie from './services/cookie';
import Home from './pages/Home/index.jsx';
import Login from './pages/Login/index.jsx';
import Users from './pages/Users/index.jsx';
import Team from './pages/Team/index.jsx';
import Groups from './pages/Groups/index.jsx';
import NotFoundPage from './pages/NotFound/index.jsx';
import Main from './components/Main.jsx';

export default function Routes() {
  
  const token = cookie().get("token");

  return (
    <BrowserRouter>
    
      { (token === null) && <Redirect to="/login" /> }

      <Switch>
        <Route path="/" exact render={() => <Main Page={Home}/>} />
        <Route path="/equipe" exact render={() => <Main Page={Team}/>} />
        <Route path="/grupos" exact render={() => <Main Page={Groups}/>} />
        <Route path="/usuarios" exact render={() => <Main Page={Users}/>} />
        <Route path="/login" exact component={Login} />
        <Route component={NotFoundPage}/>
      </Switch>
    </BrowserRouter>
  );
}
