import React from 'react';
import { Card, ButtonInsert } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleCarry, faPlus } from '@fortawesome/free-solid-svg-icons'
import TeamView from '../../views/Team/index.jsx';

export default function Team() {
  return (
      <Card>
        <h1><FontAwesomeIcon icon={faPeopleCarry} /> Equipe</h1>
        
        <TeamView/>
        
        <ButtonInsert title="Cadastrar novo">
          <FontAwesomeIcon icon={faPlus} />
        </ButtonInsert>
      </Card>
  );
}
