import styled from 'styled-components';

export const Card = styled.div`
  padding: 70px;
  width: calc(100% - 140px);
  margin: 70px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
  border: 1px solid #ddd;

  h1 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
  }
`;

export const ButtonInsert = styled.button`
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  padding: 20px;
  height: 80px;
  width: 80px;
  border-radius: 100px;
  border: 0;
  color: #fff;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
`;