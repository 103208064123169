import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalExample = ({title, size, Content, modal, setModal}) => {
  
  const toggle = () => setModal(!modal);

  return (
    <Modal isOpen={modal} toggle={toggle} size={size}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Content/>
      </ModalBody>
    </Modal>
  );
}

export default ModalExample;