import React, {useState, useCallback} from 'react';
import ListReports from './list.jsx'
import FormReports from './form.jsx'
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import {SessionManager} from '../../utils/functions';

export default function Reports() {

  const [reports, setReports] = useState([]);
  const [message, setMessage] = useState(""); 
  const [fields, setFields] = useState({dateStart:'', dateEnd: '', patient: ''});
  const [inProgress, setInProgress] = useState(false);
  const token = cookie().get("token");
  const listPerPage = 100
  const [qtdReports, setQtdReports] = useState(0);
  const session = SessionManager()
  const [pageSelected, setPageSelected] = useState(1);


  const cleanSearch = useCallback((message = "") => {
    setFields({dateStart:'', dateEnd: '', patient: ''})
    setMessage(message)
    setReports([])
  },[setFields, setMessage, setReports])

  const getSizeReports = useCallback(async () => {

    const reportData = {
      id: cookie().get("id"),
      role: cookie().get("role"),
      code: cookie().get("code"),
      patient: fields.patient,
      date_start: fields.dateStart,
      date_end: fields.dateEnd,
    };

    const response = await api()
		.report()
		.getSizeList(
			token,
			reportData,
		);
    
    if (response.status === 401) 
    {
      await session.logoff();
      return 0
    } 
    
    if (response.status === 200) 
    {      
      console.log(response);
      return response.output.data.Total_Exame;
    } 
    return 0
  }, [fields, token, session])

  const getReports = useCallback(async (e) => {
    e.preventDefault();

    setInProgress(true)
    const size = await getSizeReports();

    if (size === 0) {
      cleanSearch("Nenhum laudo encontrado")
      setInProgress(false)
      setQtdReports(0)
      return
    }

    const reportData = {
      id: cookie().get("id"),
      role: cookie().get("role"),
      code: cookie().get("code"),
      patient: fields.patient,
      date_start: fields.dateStart,
      date_end: fields.dateEnd,
      size: listPerPage,
      number: 1,
    };

    // const response = await api().report().get(token, reportData);
    const response = await api().report().getAll(token, reportData);

    if (response.status === 200) 
    {

      setQtdReports(size)
      setMessage(size +" resultados encontrados")
      setReports(response.output.data)
      setInProgress(false)
      setPageSelected(1)
      
      return
    } 

    cleanSearch("Nenhum laudo encontrado")
    setInProgress(false)
    
  },[setInProgress, fields, token, getSizeReports, setMessage, setReports, cleanSearch, setQtdReports])

  const getOtherPage = useCallback(async (page) => {

    const reportData = {
      id: cookie().get("id"),
      role: cookie().get("role"),
      code: cookie().get("code"),
      patient: fields.patient,
      date_start: fields.dateStart,
      date_end: fields.dateEnd,
      size: listPerPage,
      number: page,
    };

    // const response = await api().report().get(token, reportData);
    const response = await api().report().getAll(token, reportData);

    if (response.status === 200) 
    {

      setReports(response.output.data)
      setInProgress(false)
      
      return
    } 

    setReports([])
  }, [setInProgress, setReports, token, fields])

  return (
    <div>
      <FormReports setFields={setFields} getReports={getReports} cleanSearch={cleanSearch} inProgress={inProgress} fields={fields}/>
      <ListReports reports={reports} message={message} getOtherPage={getOtherPage} qtdReports={qtdReports} listPerPage={listPerPage} pageSelected={pageSelected} setPageSelected={setPageSelected}/>
    </div>
  );
}