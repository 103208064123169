import React, {useState} from 'react';
import { 
  Spinner,
  FormGroup,
  Form,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Row,
  Col,
} from 'reactstrap';
import { FormWrapperForm, InputView } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default function FormReports({fields, setFields, getReports, cleanSearch, inProgress}) {

  return (
    <div>
      
      <FormWrapperForm>

        <Form onSubmit={getReports}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      Data Início
                    </InputGroupText>
                  </InputGroupAddon>

                  <InputView required value={fields.dateStart} type="date" onChange={(e) => setFields({...fields, dateStart:e.target.value})}/>

                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      Data Fim
                    </InputGroupText>
                  </InputGroupAddon>

                  <InputView required value={fields.dateEnd} type="date" onChange={(e) => setFields({...fields, dateEnd:e.target.value})}/>

                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  Nome Paciente
                </InputGroupText>
              </InputGroupAddon>

              <InputView type="text" value={fields.patient} onChange={(e) => setFields({...fields, patient:e.target.value})}/>

            </InputGroup>
          </FormGroup>
          


          { (inProgress) ?
              <Spinner color="primary" />
            :
            <>
              <Button color="primary" type="submit">
                <FontAwesomeIcon icon={faSearch} /> Buscar
              </Button>
              <Button color="primary" onClick={() => cleanSearch("")}>
                Limpar busca
              </Button>
            </>
          }
        </Form>

      </FormWrapperForm>

    </div>
  );
}