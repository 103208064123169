import ApiConnection from './connection';

const getMyOwn = async (token) => ApiConnection.get(`groups/my-own`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const getByUser = async (token) => ApiConnection.get(`groups`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const deleteByID = async (token, id) => ApiConnection.delete(`groups/${id}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const create = async (token, groupData) => ApiConnection.post(`groups`, groupData, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

export default function group() {
  return {
    getByUser,
    getMyOwn,
    create,
    deleteByID
  }
}