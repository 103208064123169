import styled from 'styled-components';

export const Container = styled.div`
  height: -webkit-calc(100vh);
  height: -moz-calc(100vh);
  height: calc(100vh);
  width: 100%;
  display:inline-flex;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const LeftDiv = styled.div`
  flex: 2;
  height: 100%;
  background: url(${(props) => (props.background)});
  background-size: 100%;
  padding: 100px;
  position: relative;
  
  h1 {
    position: absolute;
    width: calc(100% - 200px);
    height: 150px;
    left: 100px;
    bottom: 100px;

    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 50px;
    color: #FFF;
    text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 900px) {
    padding: 20px;
    height: 300px;
    background-size:100%;

    h1 {
      width: calc(100% - 100px);
      left: 50px;
      bottom: 50px;
      font-size: 30px;
      line-height: 30px;
    }
  }

  @media (max-width: 470px) {
    background-size: auto 100%;
    height: 400px;

    h1 {
      top: 100px;
    }
  }
`;

export const RightDiv = styled.div`
  background: #FFF;
  text-align:center;
  flex: 1;
  height: 100%;
  padding: 100px;
  position: relative;

  h1 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    margin: 50px 0;
    font-weight: 700;
  }

  img {
    position: absolute;
    bottom: 50px;
    right: 50px;
    width:150px;
  }

  @media (max-width: 900px) {
    padding: 50px;
    height: 580px;
    
    h1 {
      margin: 30px 0;
    }
  }
`;