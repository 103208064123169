import cookie from '../services/cookie/index';
import {useHistory} from 'react-router-dom';
import { useCallback } from 'react';


export const formatDate = (timestamp) => {
  if (timestamp) {
    let timestampSplited = timestamp.split("T");
    let dateSplited = timestampSplited[0].split("-")
  
    return dateSplited[2] + "/" + dateSplited[1] + "/" + dateSplited[0]
  }
  return ""
}

export const formatDateNew = (timestamp) => {
	if (timestamp === null) {
		return "00/00/0000";
	}

	let timestampSplited = timestamp.split(" ");
	let dateSplited = timestampSplited[0].split("-");

	return dateSplited[2] + "/" + dateSplited[1] + "/" + dateSplited[0];
};

export const deleteCookies = async () => {
	await cookie().del("firstName");
	await cookie().del("role");
	await cookie().del("token");
	await cookie().del("code");
	await cookie().del("id");
	await cookie().del("fullname");
};

export const SessionManager = () => {
  const history = useHistory();

  const logoff = useCallback(async () => {
    await deleteCookies();
    history.push({ pathname:"/login"});
  }, [history])

  return {logoff}
}