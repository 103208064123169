import React, {useState} from 'react';
import { Card, ButtonInsert } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog, faPlus } from '@fortawesome/free-solid-svg-icons'
import UsersView from '../../views/Users/index.jsx';
import Modal from '../../components/Modal/index.jsx';
import CreateUser from '../../views/CreateUser/index.jsx';
import UpdateUser from '../../views/UpdateUser/index.jsx';
import Slide from '../../components/Slide';

export default function Users() {
  const [modal, setModal] = useState(false);
  const [userUpdateID, setUserUpdateID] = useState(0);
  const [modalUpdate, setModalUpdate] = useState(false);

  const openUpdateUser = (userID) => {
    setUserUpdateID(userID)
    setModalUpdate(true)
  }

  return (
    <div>
      
      <Slide title={<><FontAwesomeIcon icon={faUserCog} /> Usuários</>}/>

      <Card>        
        <UsersView openUpdateUser={openUpdateUser}/>
        
        <Modal title="Novo Usuário" size="lg" modal={modal} setModal={setModal} Content={CreateUser}/>
        <Modal title="Editar Usuário" size="lg" modal={modalUpdate} setModal={setModalUpdate} Content={() => <UpdateUser userID={userUpdateID}/>}/>

        <ButtonInsert title="Cadastrar novo" onClick={() => setModal(!modal)}>
          <FontAwesomeIcon icon={faPlus} />
        </ButtonInsert>
      </Card>
    </div>
  );
}
