import styled from 'styled-components';
import { Button, Input } from 'reactstrap';

export const ButtonLogin = styled(Button)`
  width: 100%;
  border-radius: 40px;
  text-align:left;
  padding: 10px 25px;
  border:none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 20px;
  height: 50px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  position: relative;

  svg {
    position:absolute;
    right: 25px;
    top: 15px;
  }
`;

export const InputLogin = styled(Input)`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  text-align:left;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
`;
