import styled from 'styled-components';
import { Input } from 'reactstrap';

export const FormWrapperForm = styled.div`
  padding: 20px;
  text-align: right;

  label {
    font-weight: 600;
    margin: 0;
    text-align: left;
    display: block;
  }

  button[type="submit"] {
    background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
    border-radius: 40px;
    border: none;
    padding: 10px 25px;
    margin-top: 20px;
  }
  button:focus {
    outline: none;
  }
`;

export const InputView = styled(Input)`
  ::placeholder {
    color: #ccc;  
  }
`;

export const WrapperCodeInstitucional = styled.div`
  display: block;
  border-radius: .25rem;
  padding: 20px;
  text-align: left;
  background: #f3f3f3;
  
  .badge {
    margin: 0 2px;
  }

  svg {
    padding: 2px 0;
    cursor: pointer;
  }
`;
