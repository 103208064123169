import React, {useEffect, useState} from 'react';
import { Card, Button, Wrapper, Respects } from './styles';
import Reports from '../../views/Reports';
import ReportsGroup from '../../views/ReportsGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faMicroscope } from '@fortawesome/free-solid-svg-icons'
import Slide from '../../components/Slide';
import api from '../../services/client/api'
import cookie from '../../services/cookie/index'
import {deleteCookies} from '../../utils/functions';
import { useHistory } from 'react-router-dom';

export default function Home() {
  const [tab, setTab] = useState('reports');
  const [first, setFirst] = useState(true);
  const [firstName, setFirstName] = useState("");
  const history = useHistory();
  const [groupsThatIBelong, setGroupsThatIBelong] = useState([]);


  useEffect(() => {
    if (first) {
      getGroupsThatIBelong()
      getFirstName()
    }
  })

  const getFirstName = async () => {
    let name = await cookie().get("firstName");
    setFirstName(name)
  }

  const getGroupsThatIBelong = async () => {

    const token = await cookie().get("token")
    const response = await api().group().getByUser(token);
    
    if (response.status == 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    setFirst(false)
    
    if (response.status == 200) 
    {
      setGroupsThatIBelong(response.output)
      return
    } 
    setGroupsThatIBelong([])

  }

  return (
    <div>

      <Slide title={<><FontAwesomeIcon icon={faHome} /> Home</>}/>

      <Respects>Olá <b>{firstName}</b>!</Respects>
      <Card>

        <h1><FontAwesomeIcon icon={faMicroscope} /> Busca de exames</h1>
        
        {groupsThatIBelong.length > 0 &&
          <Wrapper>
            <Button onClick={() => setTab('reports')} active={tab == 'reports'&&true}>
              Laudos Gerais
            </Button>
            <Button onClick={() => setTab('reportsGroup')} active={tab == 'reportsGroup'&&true}>
              Laudos por grupo
            </Button>
          </Wrapper>
        }
        
        {tab == 'reports' ? 
          <Reports />
        :
          <ReportsGroup groupsThatIBelong={groupsThatIBelong}/>
        }
        
      </Card>
    </div>
  );
}
