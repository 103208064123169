import styled, { css } from 'styled-components';
import { Input } from 'reactstrap';

export const FormWrapperForm = styled.div`
    
  h2 {
    color: #007DC3;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin: 30px 0;
    font-size: 24px;
  }

  label {
    font-weight: 600;
    margin: 0;
    text-align: left;
    display: block;
  }

  button[type="submit"], button {
    background: linear-gradient(268.2deg,#006EB0 -5.28%,#00AFEF 100%,#007DC3 100%);
    border-radius: 40px;
    border: none;
    padding: 10px 50px;
    margin-top: 20px;
    font-size: 23px;
    text-align: left;
  }

  button:focus {
    outline: none;
  }
  
  @media (min-width: 991px) {
    button {
      margin-right: 20px;
    }
  }

  @media (max-width: 990px) {
    button[type="submit"], button {
      width: 100%;
      text-align:center;
      font-size: 18px
    }
  }
`;

export const InputView = styled(Input)`
  ::placeholder {
    color: #ccc;  
  }
`;

export const WrapperEmailList = styled.div`
    display: block;
    border-radius: .25rem;
    padding: 20px 10px;
    text-align: left;
    background: #f5f5f5;
  .badge {
    margin: 0 2px;
  }
`;

export const Button = styled.button`
  padding:  5px 10px;
  border: none;
  margin: 2px;
  border-radius: 5px;
  ${({activated}) => activated&&css`
    background: #00AFEF;
    color: #FFF;
  `}
`