import ApiConnection from './connection';

const getByUser = async (token, userID) => ApiConnection.get(`user-institutions/${userID}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

export default function userInstitution() {
  return {
    getByUser
  }
}