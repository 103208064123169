import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;  
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  z-index: 10;
  
  .spinner-border {
    width: 20px;
    height: 20px;
  }
  
  span {
    height: 20px;
    font-size: 20px;
    position: absolute;
    top: calc(50% - 20px);
  }

`;