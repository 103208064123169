import user from './user';
import userInstitution from './userInstitution';
import report from './report';
import group from './group';

const api = () => {

  return {
    user,
    userInstitution,
    report,
    group
  }
}

export default api;